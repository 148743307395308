import React from 'react';
import classNames from 'classnames';
import Button from '../Button';
import InfoMessage from '../InfoMessage';
// @ts-ignore
import { ReactComponent as FileIcon } from '../../../assets/vectors/FileIcon.svg';
// @ts-ignore
import { ReactComponent as FileRemoveIcon } from '../../../assets/vectors/FileRemoveIcon.svg';
// @ts-ignore
import { ReactComponent as FileUploadedIcon } from '../../../assets/vectors/FileUploadedIcon.svg';
// @ts-ignore
import { ReactComponent as FileUploading } from '../../../assets/vectors/FileUploading.svg';
// @ts-ignore
import { ReactComponent as WarningIcon } from '../../../assets/vectors/WarningIcon.svg';

const style = require('./DropZone.module.scss');

type DropZoneProps = {
  getRootProps: Function;
  getInputProps: Function;
  clearFiles: Function;
  isDragActive: boolean;
  isActive?: boolean;
  className?: string;
  hasBillError?: boolean;
  isBillUploadSuccess?: boolean;
  [otherProps: string]: any;
};

const DropZone = (props: DropZoneProps) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    acceptedFiles = [],
    clearFiles,
    className,
    isActive,
    hasBillError = false,
    isBillUploadSuccess = false,
    ...rest
  } = props;

  const getButtonText = (isDragging: boolean, hasFilesAccepted: boolean) => {
    if (isDragging) {
      return 'Drop Your Bill Here';
    }
    if (hasFilesAccepted) {
      return 'Upload Another Bill';
    }
    return 'Upload Your Bill';
  };

  const getFileStatusIcon = (uploadSuccessful: boolean) => {
    if (uploadSuccessful) return <FileUploadedIcon width={24} height={24} />;
    if (hasBillError) return <WarningIcon width={24} height={24} />;
    return <FileUploading width={24} height={24} className={style.rotating} />;
  };

  return (
    <div className={classNames(style.dropZoneWrapper, className)}>
      <div
        {...rest}
        {...getRootProps()}
        className={classNames(style.dropZone, {
          [style.hoverDrop]: isDragActive,
          [style.active]: isActive,
          [style.disabled]: !isActive,
          [style.extraPaddingBottom]: !acceptedFiles.length,
        })}
        tabIndex={-1}
      >
        <input {...getInputProps()} tabIndex={-1} />
        {acceptedFiles.map((acceptedFile: any, index: any) => (
          <div
            key={index}
            className={style.uploadedFilesContainer}
            style={{ marginBottom: acceptedFiles.length ? '0' : '24px' }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <div className={style.uploadedFile}>
              <FileIcon style={{ flexShrink: 0 }} width={22} height={28} />
              <p className={style.fileName}>{acceptedFile.path}</p>
              <div className="horizontal-spacer" />
              <div className={style.uploadStatus}>
                {getFileStatusIcon(isBillUploadSuccess)}
              </div>
              <div
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  clearFiles();
                }}
              >
                <FileRemoveIcon width={20} height={20} />
              </div>
            </div>
          </div>
        ))}
        {!acceptedFiles.length && (
          <>
            <Button type="button" variant="tertiary" disabled={!isActive}>
              {getButtonText(isDragActive, !!acceptedFiles.length)}
            </Button>
            <div className={style.dragDropTip}>
              or drag & drop your bill here
            </div>
          </>
        )}
      </div>
      <div className={style.messageArea}>
        {hasBillError && (
          <InfoMessage variant="warning">
            Hmm.. it looks like we can&apos;t read that file. Please try again,
            or select ‘I have a paper bill’ to proceed.
          </InfoMessage>
        )}
        {isBillUploadSuccess && (
          <InfoMessage variant="success">
            Your bill has been successfully uploaded and processed!
          </InfoMessage>
        )}
      </div>
    </div>
  );
};

DropZone.defaultProps = {
  className: '',
  hasBillError: false,
  isActive: true,
  isBillUploadSuccess: false,
};

export default DropZone;
