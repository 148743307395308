// extracted by mini-css-extract-plugin
export var dropZoneWrapper = "DropZone-module--dropZoneWrapper--164OU";
export var dropZone = "DropZone-module--dropZone--3RxzO";
export var extraPaddingBottom = "DropZone-module--extraPaddingBottom--2ZqFj";
export var active = "DropZone-module--active--30wo5";
export var hoverDrop = "DropZone-module--hoverDrop--23vEn";
export var disabled = "DropZone-module--disabled--29hcA";
export var uploadedFilesContainer = "DropZone-module--uploadedFilesContainer--16P4z";
export var uploadedFile = "DropZone-module--uploadedFile--25-eU";
export var fileName = "DropZone-module--fileName--159IS";
export var uploadStatus = "DropZone-module--uploadStatus--d7pYe";
export var messageArea = "DropZone-module--messageArea--1vciv";
export var dragDropTip = "DropZone-module--dragDropTip--2Jp4k";
export var rotating = "DropZone-module--rotating--30NUW";